import { useReducer, useState } from "react";
import {
  Box,
  Button,
  FormRow,
  FormSection,
  LoadingButton,
  PlusIcon,
  ReadOnlyField,
  Space,
  TextField,
  useIsMobileLayout,
  useForm,
  validators,
  LocalizedString,
  fieldIssues,
  TextChildren,
} from "design-system";

import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import {
  foldState,
  reducer,
  showInputAction,
  handleSuccessAction,
  handleFailureAction,
  resetAction,
} from "./PromoFormState";
import * as api from "./api";
import { useValidators } from "../Common/useValidators";
import { useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import { useIsRemoteChannel } from "../useChannel";

type Props = {
  initialPromoCode: Option<NonEmptyString>;
  onApply: (code: NonEmptyString) => unknown;
  onRemove: () => unknown;
  onValidFormChange: (isValid: boolean) => unknown;
  showError?: boolean;
  disabled?: boolean;
} & (
  | {
      readonly?: false;
      message?: LocalizedString;
    }
  | {
      readonly: true;
      message: LocalizedString;
    }
);

export function PromoForm(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const promoCodeValidate = useCommand(api.promoCodeValidate);
  const promoCodeRemove = useCommand(api.promoCodeRemove);
  const isRemote = useIsRemoteChannel();
  const [state, dispatch] = useReducer(
    reducer,
    props.message
      ? { view: "Verified", success: true, message: props.message }
      : isRemote && option.isNone(props.initialPromoCode)
      ? { view: "InputHidden" }
      : { view: "InsertCode" }
  );
  const { nonBlankString, alphaNumeric, maxLength } = useValidators();
  const { fieldProps, values, setValues, handleSubmit, resetForm } = useForm(
    {
      initialValues: {
        promoCode: pipe(
          props.initialPromoCode,
          option.getOrElse(() => "")
        ),
      },
      fieldValidators: () => ({
        promoCode: validators.inSequence(
          nonBlankString,
          alphaNumeric,
          maxLength(13)
        ),
      }),
      validateOnChange: false,
      validateOnBlur: false,
    },
    {
      onSubmit: formValue =>
        pipe(
          promoCodeValidate(formValue),
          taskEither.fold(
            () =>
              taskEither.fromIO(() =>
                dispatch(
                  handleFailureAction(
                    formatMessage(
                      "Identification.MainContent.promoForm.genericError"
                    )
                  )
                )
              ),
            responseBody =>
              taskEither.fromIO(() => {
                if (responseBody.result === "Y") {
                  props.onApply(formValue.promoCode);
                  props.onValidFormChange(true);
                  dispatch(handleSuccessAction(responseBody.landingPageText));
                } else {
                  props.onValidFormChange(false);
                  dispatch(handleFailureAction(responseBody.validationMessage));
                }
              })
          )
        ),
    }
  );
  const [appliedInitialCode, setAppliedInitialCode] = useState(
    option.isNone(props.initialPromoCode)
  );
  const dispatchClick = (buttonRef: HTMLButtonElement | null) => {
    if (buttonRef && !appliedInitialCode && !props.readonly) {
      buttonRef.focus();
      const keyboardEvent = new KeyboardEvent("keydown", {
        code: "Enter",
        key: "Enter",
        keyCode: 13,
        view: window,
        bubbles: true,
      });
      buttonRef.dispatchEvent(keyboardEvent);
      setAppliedInitialCode(true);
    }
  };
  const buttonSpacing = !isMobileLayout && <Space units={6} />;

  return pipe(
    state,
    foldState({
      whenInputHidden: () => (
        <Box>
          <Button
            variant="text"
            size="default"
            icon={PlusIcon}
            label={formatMessage(
              "Identification.MainContent.promoForm.showInput"
            )}
            action={() => dispatch(showInputAction())}
            disabled={props.disabled}
          />
        </Box>
      ),
      whenInsertCode: () => (
        <FormSection>
          <FormRow type="3-1" grow>
            <TextField
              {...fieldProps("promoCode")}
              label={formatMessage(
                "Identification.MainContent.promoForm.title"
              )}
              assistiveText={formatMessage(
                "Identification.MainContent.promoForm.body"
              )}
              placeholder={formatMessage(
                "Identification.MainContent.promoForm.placeholder"
              )}
              onChange={value => {
                props.onValidFormChange(true);
                fieldProps("promoCode").onChange(value);
                if (
                  fieldProps("promoCode").value.length == 0 &&
                  props.showError !== undefined
                ) {
                  resetForm();
                }
              }}
              issues={
                props.showError === undefined
                  ? fieldProps("promoCode").issues
                  : props.showError
                  ? option.some(fieldIssues.errors(["" as TextChildren]))
                  : fieldProps("promoCode").value.length == 0
                  ? option.none
                  : fieldProps("promoCode").issues
              }
            />
            <>
              {buttonSpacing}
              <LoadingButton
                key="apply-code-button"
                type="submit"
                variant="secondary"
                size="default"
                ref={dispatchClick}
                labels={{
                  loading: formatMessage(
                    "Identification.MainContent.promoForm.loading"
                  ),
                  error: formatMessage(
                    "Identification.MainContent.promoForm.submit"
                  ),
                  normal: formatMessage(
                    "Identification.MainContent.promoForm.submit"
                  ),
                  success: formatMessage(
                    "Identification.MainContent.promoForm.submit"
                  ),
                }}
                action={handleSubmit}
                disabled={props.disabled}
              />
            </>
          </FormRow>
        </FormSection>
      ),
      whenVerified: (success, message) => (
        <FormSection>
          <FormRow type="3-1" grow>
            <ReadOnlyField
              size="medium"
              label={formatMessage(
                "Identification.MainContent.promoForm.title"
              )}
              value={values.promoCode}
              feedback={{ type: success ? "success" : "error", message }}
            />
            {!props.readonly ? (
              <>
                {buttonSpacing}
                {success ? (
                  <LoadingButton
                    key="remove-code-button"
                    size="default"
                    variant="secondary"
                    labels={{
                      normal: formatMessage(
                        "Identification.MainContent.promoForm.remove"
                      ),
                      loading: formatMessage(
                        "Identification.MainContent.promoForm.loading"
                      ),
                      success: formatMessage(
                        "Identification.MainContent.promoForm.remove"
                      ),
                      error: formatMessage(
                        "Identification.MainContent.promoForm.remove"
                      ),
                    }}
                    action={pipe(
                      promoCodeRemove(),
                      taskEither.chain(() =>
                        taskEither.fromIO(() => {
                          setValues({ promoCode: "" });
                          setAppliedInitialCode(true);
                          props.onRemove();
                          props.onValidFormChange(true);
                          dispatch(resetAction());
                        })
                      )
                    )}
                    disabled={props.disabled}
                  />
                ) : (
                  <Button
                    size="default"
                    variant="secondary"
                    label={formatMessage(
                      "Identification.MainContent.promoForm.retry"
                    )}
                    action={() => {
                      setValues({ promoCode: "" });
                      props.onValidFormChange(true);
                      dispatch(resetAction());
                    }}
                    disabled={props.disabled}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </FormRow>
        </FormSection>
      ),
    })
  );
}
