import { NonNegativeInteger } from "design-system";
import { apiCall } from "../../APICall";
import * as t from "io-ts";
import { eq } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { optionFromUndefined } from "../../globalDomain";
import { ApplicationStatus } from "../../Common/ProcessStoreList/api";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { OtpGenerationError, OtpVerifyError } from "../../OTP/domain";
import { OTPGenerationOutput } from "../../PhoneAndEmailVerification/api";
import {
  ApplicationOtpGenerationInput,
  ApplicationOtpVerifyInput,
} from "../../BankerLanding/api";
import { constFalse, constTrue } from "fp-ts/function";
import { UUID } from "io-ts-types/lib/UUID";

export const MortgageApplicationStatusOK = t.keyof(
  {
    OK: true,
  },
  "MortgageApplicationStatusOK"
);
export type MortgageApplicationStatusOK = t.TypeOf<
  typeof MortgageApplicationStatusOK
>;

export const MortgageApplicationStatusNotOK = t.keyof(
  {
    LINK_EXPIRED: true,
    APPLICATION_INAPPROPRIATE_STATE: true,
  },
  "ApplicationListStatus"
);
export type MortgageApplicationStatusNotOK = t.TypeOf<
  typeof MortgageApplicationStatusNotOK
>;

export const MortgageApplicationStatus = t.union([
  MortgageApplicationStatusOK,
  MortgageApplicationStatusNotOK,
]);

export type MortgageApplicationStatus = t.TypeOf<
  typeof MortgageApplicationStatus
>;

export const MaxVerifyPhoneNumberAttemptsReachedError = t.type({
  id: t.literal("MaxVerifyPhoneNumberAttemptsReached"),
});

export const InvalidPhoneNumberError = t.type({
  id: t.literal("InvalidPhoneNumber"),
  attemptsLeft: NonNegativeInteger,
});

const ApplicationIsLockedError = t.type({
  id: t.literal("ApplicationIsLocked"),
});
export const CheckPhoneNumberAPIError = t.union([
  InvalidPhoneNumberError,
  MaxVerifyPhoneNumberAttemptsReachedError,
  ApplicationIsLockedError,
]);

export type CheckPhoneNumberAPIError = t.TypeOf<
  typeof CheckPhoneNumberAPIError
>;

const PhoneMatchErrorId = t.keyof({
  MaxPhoneChecksAttemptsReached: true,
  InvalidPhone: true,
});

const GetMaskedPhoneNumberInput = t.type({
  applicationId: t.string,
});

export type GetMaskedPhoneNumberInput = t.TypeOf<
  typeof GetMaskedPhoneNumberInput
>;

export const eqGetMaskedPhoneNumberInput: Eq<GetMaskedPhoneNumberInput> = eq.getStructEq(
  {
    applicationId: eq.eqString,
  }
);

const GetMaskedPhoneNumberOutput = t.type({
  maskedPhoneNumber: t.string,
  attemptsLeft: NonNegativeInteger,
  errorId: optionFromUndefined(PhoneMatchErrorId),
});

export type GetMaskedPhoneNumberOutput = t.TypeOf<
  typeof GetMaskedPhoneNumberOutput
>;

export const getMaskedPhoneNumber = apiCall({
  inputCodec: GetMaskedPhoneNumberInput,
  inputEq: eqGetMaskedPhoneNumberInput,
  path: [
    "authorization",
    "remote",
    "client",
    "identification",
    "noauth",
    "phoneNumber",
    "masked",
  ],
  outputCodec: GetMaskedPhoneNumberOutput,
});

const CheckMaskedPhoneNumberInput = t.type({
  applicationId: t.string,
  phoneNumber: t.string,
});

export type CheckMaskedPhoneNumberInput = t.TypeOf<
  typeof CheckMaskedPhoneNumberInput
>;

const CheckMaskedPhoneNumberOutput = t.type({
  success: t.boolean,
  remainingAttempts: NonNegativeInteger,
  errorId: optionFromUndefined(PhoneMatchErrorId),
});

export const eqCheckMaskedPhoneNumberInput: Eq<CheckMaskedPhoneNumberInput> = eq.getStructEq(
  {
    phoneNumber: eq.eqString,
  }
);

export const checkMaskedPhoneNumber = apiCall({
  inputCodec: CheckMaskedPhoneNumberInput,
  inputEq: eqCheckMaskedPhoneNumberInput,
  path: [
    "authorization",
    "remote",
    "client",
    "identification",
    "noauth",
    "phoneNumber",
    "masked",
    "verify",
  ],
  outputCodec: CheckMaskedPhoneNumberOutput,
  errorCodec: CheckPhoneNumberAPIError,
});

export const ProductType = t.keyof({
  MORTGAGE: true,
  UKONTO: true,
  SAVINGS: true,
  CL: true,
  SL: true,
});

export type ProductType = t.TypeOf<typeof ProductType>;

const GetApplicationStatusInput = t.type({
  applicationId: t.string,
});

const eqGetApplicationStatusInput = eq.getStructEq({
  applicationId: eq.eqString,
});

const GetApplicationStatusOutput = t.type({
  status: ApplicationStatus,
  newFlowId: optionFromUndefined(NonEmptyString),
});

export const getStoredApplicationStatus = apiCall({
  inputCodec: GetApplicationStatusInput,
  inputEq: eqGetApplicationStatusInput,
  path: ["utilities", "remote", "application", "status"],
  outputCodec: GetApplicationStatusOutput,
});

const GetMortgageApplicationStatusInput = t.type({
  applicationNumber: t.string,
});

const eqGetMortgageApplicationStatusInput = eq.getStructEq({
  applicationNumber: eq.eqString,
});

const GetMortgageApplicationStatusOutput = t.type({
  status: MortgageApplicationStatus,
});

export const getMortgageApplicationStatus = apiCall({
  inputCodec: GetMortgageApplicationStatusInput,
  inputEq: eqGetMortgageApplicationStatusInput,
  path: ["packages", "mortgage", "uploaddocumentsclient", "checks"],
  outputCodec: GetMortgageApplicationStatusOutput,
});

export const generateOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "authorization",
    "remote",
    "client",
    "identification",
    "noauth",
    "otp",
  ],
  inputCodec: ApplicationOtpGenerationInput,
  outputCodec: OTPGenerationOutput,
  errorCodec: OtpGenerationError,
});

export const verifyOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "authorization",
    "remote",
    "client",
    "identification",
    "noauth",
    "otp",
    "verify",
  ],
  inputCodec: ApplicationOtpVerifyInput,
  errorCodec: OtpVerifyError,
});

const GetTokens = t.type({
  flowId: UUID,
  token: NonEmptyString,
  refreshToken: NonEmptyString,
});

export const getTokens = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: [
    "authorization",
    "remote",
    "client",
    "identification",
    "noauth",
    "token",
  ],
  outputCodec: GetTokens,
});

const getNotificationStatusInput = t.type({
  applicationNumber: t.string,
});

const eqGetNotificationStatusInput = eq.getStructEq({
  applicationNumber: eq.eqString,
});

const getNotificationStatusOutput = t.type({
  status: t.keyof({ NONE: true, NOT_SENT: true, SENT: true }),
});

export const getNotificationStatus = apiCall({
  inputCodec: getNotificationStatusInput,
  inputEq: eqGetNotificationStatusInput,
  path: [
    "packages",
    "mortgage",
    "uploaddocumentsclient",
    "getNotificationStatus",
  ],
  outputCodec: getNotificationStatusOutput,
});
