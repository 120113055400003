import { ComponentProps, useEffect, useMemo, useRef, useState } from "react";
import {
  Banner,
  Box,
  LocalizedString,
  ReadOnlyField,
  Space,
  unsafePositiveInteger,
  VerificationCard,
} from "design-system";
import { boolean, eq, option, taskEither } from "fp-ts";
import {
  constant,
  constUndefined,
  constVoid,
  flow,
  pipe,
} from "fp-ts/function";
import {
  completeAction,
  editPhoneNumberAction,
  OtpError,
  reducerConfig,
  setCodeAction,
  setLoadingAction,
  setOtpRequestErrorAction,
  setOtpVerifyErrorAction,
  setPhoneNumberAction,
} from "./state";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import * as verificationApis from "../api";
import { CoApplicantInput, GenericError } from "../../globalDomain";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";

import { usePhoneNumberFieldProps } from "../../Common/PhoneNumberField/usePhoneNumberFieldProps";
import { PrivacyPolicyDisclaimer } from "../../Common/PrivacyPolicyDisclamer/PrivacyPolicyDisclaimer";
import { useSnackBar } from "../../useSnackbar";
import { UnderAgeError } from "../../StandardLoan/domain";
import { usePropagateHasChanged } from "../../ClientProfile/usePropagateHasChanged";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";
import { usePortalStatusContext } from "../../PortalStatusContext";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { useAppContext } from "../../useAppContext";

export type GenerateOTP = ReaderTaskEither<
  verificationApis.OtpGenerationInput,
  verificationApis.OtpGenerationError | GenericError,
  verificationApis.OTPGenerationOutput
>;

export type VerifyOTP = ReaderTaskEither<
  verificationApis.OtpVerifyInput,
  verificationApis.OtpVerifyError | GenericError,
  unknown
>;

type Props = {
  initialPhoneNumber: Option<NonEmptyString>;
  canEdit: boolean;
  contactDetailsDuplicityCheck: boolean;
  onPhoneVerified: Option<
    ReaderTaskEither<string, UnderAgeError | unknown, unknown>
  >;
  onComplete: () => unknown;
  generateOTP: GenerateOTP;
  verifyOTP: VerifyOTP;
  onHasChanged?: (hasChanged: boolean) => unknown;
  restoredData?: RestoreApplicationData;
  onMaxNumberChangesReached?: (
    error: verificationApis.OtpGenerationError
  ) => unknown;
} & LayoutProps &
  CoApplicantInput;

type LayoutProps =
  | {
      layout: "embedded";
    }
  | {
      layout: "standalone";
      heading: LocalizedString;
    };

export function PhoneVerification(props: Props) {
  const formatMessage = useFormatMessage();
  const { portalBlocked } = usePortalStatusContext();

  const { showSnackbar } = useSnackBar();

  const {
    config: { recaptchaEnabled, recaptchaPublicKey },
  } = useAppContext();

  const [status, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.restoredData &&
      option.isSome(props.restoredData.contactsCollectionData)
      ? {
          view: "Verified",
          phoneNumber:
            props.restoredData.contactsCollectionData.value.phoneNumber,
        }
      : {
          view: "InsertPhoneNumber",
          phoneNumber: props.initialPhoneNumber,
          error: option.none,
        }
  );

  const checkPhoneNumberDuplicity = useCommand(
    verificationApis.phoneNumberDuplicityCheck
  );

  const handleVerificationOnSuccess =
    status.view === "VerifyingOTP"
      ? pipe(
          props.onPhoneVerified,
          option.fold(
            () => taskEither.of(null),
            onPhoneVerified => onPhoneVerified(status.phoneNumber)
          ),
          taskEither.mapLeft(() => "GenericError"),
          taskEither.chain(() =>
            pipe(
              props.contactDetailsDuplicityCheck,
              boolean.fold(
                () => taskEither.fromIO(constVoid),
                () =>
                  pipe(
                    checkPhoneNumberDuplicity({
                      mobilePhoneNumber: status.phoneNumber,
                    }),
                    taskEither.map(constVoid)
                  )
              )
            )
          ),
          taskEither.chain(() =>
            taskEither.fromIO(() => {
              dispatch(completeAction(status.phoneNumber));
              props.onComplete();
            })
          )
        )
      : taskEither.left(null);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const {
    phoneNumberFieldProps,
    handlePhoneNumberSubmit,
  } = usePhoneNumberFieldProps({
    initialPhoneNumber: props.initialPhoneNumber,
    onSubmit: () => {
      pipe(
        props.onHasChanged,
        option.fromNullable,
        option.map(callback => callback(true))
      );
      return onRequestOTP;
    },
  });

  usePropagateHasChanged<string>({
    enabled: eq.eqString.equals(status.view, "InsertPhoneNumber"),
    initialValue: pipe(
      props.initialPhoneNumber,
      option.getOrElse(constant(""))
    ),
    fieldProps: phoneNumberFieldProps,
    equality: eq.eqString,
    onHasChanged: props.onHasChanged,
  });

  useEffect(() => {
    pipe(
      props.onHasChanged,
      option.fromNullable,
      option.map(callback =>
        callback(
          !eq.eqString.equals(status.view, "InsertPhoneNumber") ||
            pipe(
              props.initialPhoneNumber,
              option.map(
                v => !eq.eqString.equals(phoneNumberFieldProps.value, v)
              ),
              option.getOrElse(
                () => !eq.eqString.equals(phoneNumberFieldProps.value, "")
              )
            )
        )
      )
    );
  }, [phoneNumberFieldProps.value, status.view]);

  const onRequestOTP = useMemo(() => {
    const phoneNumber =
      status.view === "InsertPhoneNumber"
        ? phoneNumberFieldProps.value
        : status.phoneNumber;

    return pipe(
      props.generateOTP({
        phoneNumber,
        coApplicant: props.coApplicant,
        recaptchaToken: reCaptchaToken,
      }),
      taskEither.orElse(error =>
        taskEither.leftIO(() => {
          dispatch(setOtpRequestErrorAction(error));
          if (
            error.id === "MaxPhoneNumberChangesReached" &&
            props.onMaxNumberChangesReached
          ) {
            props.onMaxNumberChangesReached(error);
          }
          if (error.id === "OTPIPBlocked") {
            if (recaptchaEnabled) setShowRecaptcha(true);
            else if (props.onMaxNumberChangesReached)
              props.onMaxNumberChangesReached(error);
          }
          return error;
        })
      ),
      taskEither.chain(({ remainingRequests }) =>
        taskEither.rightIO(() =>
          dispatch(setPhoneNumberAction(phoneNumber, remainingRequests))
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    status.view,
    status.phoneNumber,
    props.coApplicant,
    phoneNumberFieldProps.value,
    reCaptchaToken,
  ]);

  const reSendOtp = pipe(
    onRequestOTP,
    taskEither.chain(() =>
      taskEither.fromIO(() => {
        showSnackbar({
          type: "success",
          action: option.none,
          message: formatMessage("Identification.otp.newCodeSent"),
        });
      })
    )
  );

  function errorToIssue(
    error: OtpError
  ): {
    type: Extract<ComponentProps<typeof Banner>["type"], "warning" | "error">;
    content: ComponentProps<typeof Banner>["content"];
    title: Option<LocalizedString>;
    actions: ComponentProps<typeof Banner>["actions"];
  } {
    const content = (() => {
      switch (error.id) {
        case "GenericError":
        case "MaxPhoneNumberChangesReached":
        case "InvalidFlowId":
        case "OTPIPBlocked":
          return formatMessage("Identification.otp.genericError");
        case "MaxOtpRequestsReached":
          return props.canEdit
            ? formatMessage("Identification.otp.maxRequestsChangeNumber")
            : formatMessage("Identification.otp.codeWrongVisitBranch");
        case "MaxOtpAttemptsReached":
          return formatMessage("Identification.otp.codeWrongResendCode");
        case "InvalidOTP":
          if (error.attemptsLeft === 0) {
            return formatMessage("Identification.otp.codeWrongResendCode");
          } else {
            return formatMessage("Identification.otp.invalidCode", {
              remainingAttempts: error.attemptsLeft,
            });
          }
        case "OTPExpired":
          return formatMessage("Identification.otp.expiredCode");
      }
    })();

    const actions = ((): ComponentProps<typeof Banner>["actions"] => {
      const codeWrongResendCodeAction = option.some([
        {
          variant: "secondary",
          label: formatMessage("Identification.otp.resendCode"),
          action: onRequestOTP,
        } as const,
      ]) as ComponentProps<typeof Banner>["actions"];

      switch (error.id) {
        case "GenericError":
        case "InvalidFlowId":
        case "OTPExpired":
        case "MaxPhoneNumberChangesReached":
        case "MaxOtpRequestsReached":
          if (props.canEdit) {
            return option.some([
              {
                label: formatMessage("Identification.otp.changeNumber"),
                action: flow(editPhoneNumberAction, dispatch),
                variant: "secondary",
              },
            ]);
          } else return option.none;
        case "MaxOtpAttemptsReached":
          return codeWrongResendCodeAction;
        case "InvalidOTP":
          if (error.attemptsLeft === 0) {
            return codeWrongResendCodeAction;
          }
          return option.none;
        case "OTPIPBlocked":
          return option.none;
      }
    })();

    return {
      type: "error",
      title: option.none,
      content,
      actions,
    };
  }

  const layoutProps = (() => {
    switch (props.layout) {
      case "standalone":
        return { layout: props.layout, heading: props.heading };
      case "embedded":
        return { layout: props.layout };
    }
  })();

  const verifyOtp = (code: string) =>
    taskEither.bracket(
      taskEither.rightIO(() => {
        dispatch(setLoadingAction(true));
      }),
      () =>
        pipe(
          props.verifyOTP({
            otp: code,
            coApplicant: props.coApplicant,
          }),
          taskEither.map(constUndefined),
          taskEither.orElse(error =>
            taskEither.leftIO(() => {
              if (
                error.id === "InvalidOTP" &&
                error.attemptsLeft === 0 &&
                status.view === "VerifyingOTP" &&
                status.remainingOtpRequests > 0
              ) {
                dispatch(
                  setOtpVerifyErrorAction({ id: "MaxOtpAttemptsReached" })
                );
              } else {
                dispatch(setOtpVerifyErrorAction(error));
              }
            })
          ),
          taskEither.chain(() => handleVerificationOnSuccess)
        ),
      () =>
        taskEither.rightIO(() => {
          dispatch(setLoadingAction(false));
        })
    );

  const verificationCard: JSX.Element = (() => {
    switch (status.view) {
      case "InsertPhoneNumber":
        if (
          option.isSome(props.initialPhoneNumber) &&
          !props.canEdit &&
          !portalBlocked
        ) {
          return (
            <VerificationCard
              {...layoutProps}
              state="readonlyPhoneNumber"
              phoneNumber={props.initialPhoneNumber.value}
              phoneNumberLabel={formatMessage("Identification.otp.phoneNumber")}
              verificationHeading={formatMessage(
                "Identification.otp.verifyPhoneNumber"
              )}
              verificationSubHeading={option.some(
                formatMessage("Identification.otp.requestPhoneOTPDescription")
              )}
              submitButtonProps={{
                action: handlePhoneNumberSubmit,
                labels: {
                  normal: formatMessage("Identification.otp.submitValue"),
                  loading: formatMessage("Identification.otp.submitValue"),
                  error: formatMessage("Identification.otp.submitValue"),
                  success: formatMessage("Identification.otp.submitValue"),
                },
              }}
              issue={pipe(status.error, option.map(errorToIssue))}
              valueValidationError={phoneNumberFieldProps.issues}
            />
          );
        } else {
          return (
            <VerificationCard
              {...layoutProps}
              state="insertPhoneNumber"
              phoneNumberFieldProps={{
                ...phoneNumberFieldProps,
                label: formatMessage("Identification.otp.phoneNumber"),
                placeholder: formatMessage("Identification.otp.phoneNumber"),
                description: formatMessage(
                  "Identification.MainContent.emailAndPhoneVerification.description"
                ),
              }}
              phoneNumberSubmitCta={{
                action: handlePhoneNumberSubmit,
                label: formatMessage("Identification.otp.submitValue"),
              }}
              issue={
                pipe(
                  status.error,
                  option.exists(error => error.id === "OTPIPBlocked")
                )
                  ? option.none
                  : pipe(
                      status.error,
                      option.map(error => {
                        if (error.id === "MaxOtpRequestsReached") {
                          return {
                            type: "error",
                            title: option.none,
                            content: formatMessage(
                              "Identification.otp.maxRequestsChangeNumber"
                            ),
                            actions: option.none,
                          };
                        }
                        if (error.id === "OTPIPBlocked") {
                          return {
                            type: "warning",
                            title: option.none,
                            content: formatMessage(
                              "Identification.otp.genericError"
                            ),
                            actions: option.none,
                          };
                        } else {
                          return {
                            type: "error",
                            title: option.none,
                            content: formatMessage(
                              "Identification.otp.genericError"
                            ),
                            actions: option.none,
                          };
                        }
                      })
                    )
              }
              disabled={
                portalBlocked ||
                (recaptchaEnabled &&
                  showRecaptcha &&
                  reCaptchaToken.length === 0)
              }
            />
          );
        }
      case "VerifyingOTP":
        return (
          <VerificationCard
            {...layoutProps}
            state="insertOtp"
            phoneNumber={status.phoneNumber}
            phoneNumberLabel={formatMessage("Identification.otp.phoneNumber")}
            editPhoneNumberCta={pipe(
              {
                action: flow(editPhoneNumberAction, dispatch),
                label: formatMessage("Identification.otp.editContact"),
              },
              option.fromPredicate(() => props.canEdit)
            )}
            issue={pipe(
              status.error,
              option.map(errorToIssue),
              option.altW(() =>
                pipe(
                  {
                    type: "warning",
                    title: option.none,
                    content: formatMessage(
                      "Identification.otp.lastRequestWarning"
                    ),
                    actions: option.none,
                  } as const,
                  option.fromPredicate(
                    constant(status.remainingOtpRequests === 0)
                  )
                )
              )
            )}
            otpHeading={formatMessage("Identification.otp.insertNumericCode")}
            otpSubHeading={option.some(
              formatMessage("Identification.otp.insertPhoneOTPDescription")
            )}
            loadingMessage={formatMessage("LoadingEllipsis")}
            pinInput={{
              length: unsafePositiveInteger(6),
              onSubmit: verifyOtp,
              value: status.code,
              onChange: flow(setCodeAction, dispatch),
            }}
            secondaryAction={{
              cta: {
                label: formatMessage("Identification.otp.resendCode"),
                action: reSendOtp,
              },
              label: formatMessage("Identification.otp.notReceivingSMS"),
            }}
            disabled={
              portalBlocked ||
              pipe(
                status.error,
                option.exists(
                  e =>
                    e.id === "MaxOtpAttemptsReached" ||
                    e.id === "MaxOtpRequestsReached"
                )
              )
            }
          />
        );
      case "Verified":
        return (
          <ReadOnlyField
            size="big"
            value={status.phoneNumber}
            label={formatMessage("Identification.otp.phoneNumber")}
          />
        );
    }
  })();

  return (
    <Box column shrink>
      {verificationCard}
      {recaptchaEnabled && showRecaptcha && (
        <ReCAPTCHA
          sitekey={recaptchaPublicKey}
          onChange={() => {
            setReCaptchaToken(
              recaptchaRef.current.getValue() !== null
                ? recaptchaRef.current.getValue()
                : ""
            );
          }}
          ref={recaptchaRef}
        />
      )}
      <Space units={10} />
      <PrivacyPolicyDisclaimer align="left" />
    </Box>
  );
}
