import {
  Body,
  Box,
  Children,
  FlowAccordion,
  FlowAccordionItem,
  SnackbarState,
  Space,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option, taskEither } from "fp-ts";
import { flow, pipe } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import * as verificationApis from "./api";
import {
  LinkGenerationError,
  LinkGenerationInput,
  LinkGenerationOutput,
} from "./api";
import { BeforeBegin } from "./BeforeBegin/BeforeBegin";
import { EmailVerification } from "./EmailVerification/EmailVerification";
import {
  EmailVerification as EmailVerificationType,
  EmailVerificationError,
} from "./EmailVerification/state";
import { WhatWillNeed } from "./WhatWillNeed/WhatWillNeed";
import {
  GenerateOTP,
  PhoneVerification,
  VerifyOTP,
} from "./PhoneVerification/PhoneVerification";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { useAppContext } from "../useAppContext";
import { CoApplicantInput, GenericError } from "../globalDomain";
import { useSnackBar } from "../useSnackbar";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import {
  completePhoneVerificationAction,
  reducerConfig,
  setActiveItemAction,
  setPhoneVerificationAction,
  startVerificationAction,
} from "./state";
import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";

import { useIsInPersonChannel, useIsRemoteChannel } from "../useChannel";
import { RestoreApplicationData } from "../UKontoSecondPart/api";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { useState } from "react";

type Props = {
  initialPromoCode: Option<NonEmptyString>;
  onPhoneVerified: (phoneNumber: string) => TaskEither<unknown, unknown>;
  onComplete: () => unknown;
  generateOTP: GenerateOTP;
  verifyOTP: VerifyOTP;
  linkGenerationNow: GenerateLink;
  hideInstructions?: boolean;
  saveApplication?: boolean;
  onEmailComplete?: () => unknown;
  restoredData?: RestoreApplicationData;
  onMaxNumberChangesReached?: (
    error: verificationApis.OtpGenerationError
  ) => unknown;
} & CoApplicantInput;

export type GenerateLink = ReaderTaskEither<
  LinkGenerationInput,
  LinkGenerationError | GenericError,
  LinkGenerationOutput
>;

const StepContent = (props: { children: Children }) => (
  <Box column shrink>
    <Space fluid />
    {props.children}
    <Space fluid />
  </Box>
);

export function PhoneAndEmailFlow(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    config: {
      externalCommunication,
      promotionsEnabled,
      contactDetailsDuplicityCheck,
    },
    apiParameters: { tenant },
  } = useAppContext();
  const { showSnackbar } = useSnackBar();

  const snackbarConfig: SnackbarState = {
    type: "success",
    message: formatMessage("Identification.otp.verifyPhoneNumberSuccess"),
    action: option.none,
  };
  const isRemote = useIsRemoteChannel();
  const isInPerson = useIsInPersonChannel();

  const skipVerification = isInPerson;
  const skipFirstStep =
    props.hideInstructions || (isInPerson && !promotionsEnabled);

  const generateLinkNow = props.linkGenerationNow;
  const generateLink = useCommand(
    externalCommunication
      ? verificationApis.linkGenerationLater
      : verificationApis.linkGenerationStore
  );

  const checkEmailDuplicity = useCommand(verificationApis.emailDuplicityCheck);

  const checkEmailHasDuplicate = (
    duplicateArr: verificationApis.DuplicityCheckOutput
  ) =>
    duplicateArr.some(
      duplicate =>
        duplicate.clientStatus === "POTENTIAL" ||
        duplicate.clientStatus === "FORMER" ||
        duplicate.clientStatus === "ACTIVE"
    );

  const handleRequestLink = (
    hasAlreadyConfirmedEmail: boolean,
    email: string
  ): TaskEither<EmailVerificationError, EmailVerificationType> => {
    if (hasAlreadyConfirmedEmail) {
      return pipe(
        { email, coApplicant: props.coApplicant },
        generateLinkNow,
        taskEither.bimap(
          error => error.id,
          ({ attemptsLeft }) => ({ type: "VerifyNow", attemptsLeft })
        )
      );
    } else if (!contactDetailsDuplicityCheck) {
      return pipe(
        { email, coApplicant: props.coApplicant },
        skipVerification ? generateLink : generateLinkNow,
        taskEither.bimap(
          error => error.id,
          ({ attemptsLeft }) => ({
            type: skipVerification ? "SkipVerification" : "VerifyNow",
            attemptsLeft,
          })
        )
      );
    } else {
      return pipe(
        checkEmailDuplicity({ emailAddress: email }),
        taskEither.mapLeft<unknown, EmailVerificationError>(
          () => "GenericError"
        ),
        taskEither.chain(duplicateArray => {
          if (checkEmailHasDuplicate(duplicateArray) && !isRemote) {
            return taskEither.right<
              EmailVerificationError,
              EmailVerificationType
            >({
              type: "MustConfirmEmail",
              attemptsLeft: option.none,
            });
          } else {
            return pipe(
              { email, coApplicant: props.coApplicant },
              skipVerification ? generateLink : generateLinkNow,
              taskEither.bimap(
                error => error.id,
                ({ attemptsLeft }) => ({
                  type: skipVerification ? "SkipVerification" : "VerifyNow",
                  attemptsLeft,
                })
              )
            );
          }
        })
      );
    }
  };
  const [promocode, setPromocode] = useState<Option<NonEmptyString>>(
    option.none
  );

  const [state, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.restoredData
      ? {
          activeIndex: option.some(2),
          currentStep: "EmailVerification",
        }
      : {
          activeIndex: option.some(0),
          currentStep: skipFirstStep ? "PhoneVerification" : "Instructions",
        }
  );

  const startVerification = flow(startVerificationAction, dispatch);

  const whatYouNeedStep: FlowAccordionItem = {
    title: formatMessage("Identification.MainContent.whatWillNeed.title"),
    content: () => (
      <StepContent>
        <WhatWillNeed
          initialPromoCode={props.initialPromoCode}
          tenant={tenant}
          onAccept={startVerification}
        />
      </StepContent>
    ),
  };

  const beforeYouBeginStep: FlowAccordionItem = {
    title: formatMessage("Identification.MainContent.beforeBegin.title"),
    content: () => (
      <StepContent>
        <BeforeBegin
          initialPromoCode={props.initialPromoCode}
          onSkip={startVerification}
          onAddedPromoCodeValidated={code => {
            setPromocode(option.some(code));
          }}
        />
      </StepContent>
    ),
  };

  const emailVerificationStep: FlowAccordionItem = {
    title: formatMessage(
      "Identification.MainContent.emailAndPhoneVerification.email.title"
    ),
    content: () => (
      <StepContent>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage("Identification.email.sendEmailDescription")}
        </Body>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage(
            "Identification.MainContent.emailAndPhoneVerification.emailDisclaimer"
          )}
        </Body>
        <Space units={10} />
        <EmailVerification
          coApplicant={props.coApplicant}
          initialEmail={option.none}
          canEdit
          onRequestLink={handleRequestLink}
          onComplete={props.onComplete}
          layout="embedded"
          verificationAPI={verificationApis.emailVerification}
          saveApplication={props.saveApplication}
          onEmailComplete={props.onEmailComplete}
          restoredData={props.restoredData}
          skipVerification={skipVerification}
          initialPromoCode={promocode}
        />
      </StepContent>
    ),
  };

  const phoneVerificationStep: FlowAccordionItem = {
    title: formatMessage(
      "Identification.MainContent.emailAndPhoneVerification.phone.title"
    ),
    content: () => (
      <StepContent>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage("Identification.otp.requestPhoneOTPDescription")}
        </Body>
        <Body size="medium" weight="regular" color={palette.neutral700}>
          {formatMessage(
            "Identification.MainContent.emailAndPhoneVerification.phoneDisclaimer"
          )}
        </Body>
        <Space units={10} />
        <PhoneVerification
          contactDetailsDuplicityCheck={contactDetailsDuplicityCheck}
          coApplicant={props.coApplicant}
          initialPhoneNumber={option.none}
          canEdit
          onPhoneVerified={option.some(props.onPhoneVerified)}
          onComplete={() => {
            dispatch(completePhoneVerificationAction());
            showSnackbar(snackbarConfig);
          }}
          generateOTP={config =>
            pipe(
              props.generateOTP(config),
              taskEither.map(value => {
                dispatch(setPhoneVerificationAction());
                return value;
              })
            )
          }
          onMaxNumberChangesReached={props.onMaxNumberChangesReached}
          verifyOTP={props.verifyOTP}
          layout="embedded"
          restoredData={props.restoredData}
        />
      </StepContent>
    ),
  };

  const accordionSteps: NonEmptyArray<FlowAccordionItem> = skipFirstStep
    ? [phoneVerificationStep, emailVerificationStep]
    : [
        isRemote ? whatYouNeedStep : beforeYouBeginStep,
        phoneVerificationStep,
        emailVerificationStep,
      ];
  return (
    <Box grow shrink>
      <FlowAccordion
        items={accordionSteps}
        value={state.activeIndex}
        onChange={flow(setActiveItemAction, dispatch)}
      />
    </Box>
  );
}
